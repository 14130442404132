/* Reset & Base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Wrapper to fill full vertical height */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content area grows to fill space between header and footer */
.container {
  flex: 1;
  padding: 2rem;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  background: #f9f9f9;
  color: #333;
}
a {
  color: inherit;
  text-decoration: none;
}

/* Header */
.header {
  background: linear-gradient(135deg, #667eea, #764ba2);
  color: #fff;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.header h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.header p {
  font-size: 1.2rem;
}

/* Floating quirky shapes */
.header::before,
.header::after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  animation: float 6s ease-in-out infinite;
}
.header::before {
  width: 120px;
  height: 120px;
  top: -40px;
  left: -40px;
}
.header::after {
  width: 80px;
  height: 80px;
  bottom: -30px;
  right: -30px;
  animation-duration: 8s;
}
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(20px); }
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Services Section */
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
}
.service-card {
  background: #fff;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
}
.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}
.service-card i {
  font-size: 3rem;
  color: #667eea;
  margin-bottom: 1rem;
}
.service-card h3 {
  margin-bottom: 1rem;
  font-weight: 600;
}
.service-card p {
  font-size: 0.95rem;
  color: #555;
}

/* Footer */
footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 2rem;
  margin-top: 4rem;
}
